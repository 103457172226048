import EducationIntro from "../components/EducationIntro"
import EducationCard from "../components/EducationCard"

function Education() {
    return (

        <>
            <EducationIntro />
            <EducationCard />
        </>
    )
}
export default Education