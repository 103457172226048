import Intro from "../components/Intro"
import About from "../components/About"


function Home() {
    return (
        <div className=''>
            <Intro />
            <About />
        </div>
    )
}
export default Home