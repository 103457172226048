import ProjectsIntro from "../components/ProjectsIntro"
import ProjectCards from "../components/ProjectCards"

function Projects() {
    return (
        <div className="">
            <ProjectsIntro />
            <ProjectCards />
        </div>
    )
}
export default Projects