import Headshot from '../assets/headShot.jpeg'

function Picture() {
    return (

        <div className=''>
            <img className='rounded-lg shadow-2xl' src={Headshot} alt="Adam Brannon's Headshot" />
        </div>

    )
}
export default Picture